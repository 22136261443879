import BookDrive1 from '../public/book-drive1.png';
import BookDrive2 from '../public/book-drive2.jpeg';
import BookDrive3 from '../public/book-drive3.png';
import BookDrive4 from '../public/book-drive4.png';
import BookDrive2023 from '../public/book-drive-2023.png';
import BookDrive20231 from '../public/book-drive-2023-1.png';
import BookDrive20232 from '../public/book-drive-2023-2.png';
import BookDrive20233 from '../public/book-drive-2023-3.png';
import BookDrive20234 from '../public/book-drive-2023-4.png';
import BookDrive20235 from '../public/book-drive-2023-5.png';
import BookDrive20236 from '../public/book-drive-2023-6.png';
import BookDrive20237 from '../public/book-drive-2023-7.png';
import BookDrive20238 from '../public/book-drive-2023-8.png';
import BookDrive20239 from '../public/book-drive-2023-9.png';
import EducationAccess from '../public/education-access.jpeg';
import EducationAccess1 from '../public/education-access1.jpeg';
import EducationAccess2 from '../public/education-access2.jpeg';
import EducationAccess3 from '../public/education-access3.jpeg';
import EducationAccess4 from '../public/education-access4.jpeg';
import EducationAccess5 from '../public/education-access5.jpeg';
import EducationAccess6 from '../public/education-access6.jpeg';
import EducationAccess7 from '../public/education-access7.jpeg';
import BookDriveImage1 from '../public/bookDrive1.png';
import BookDriveImage2 from '../public/bookDrive2.png';
import BookDriveImage3 from '../public/bookDrive3.png';

export const impact = [
  { name: 'Students Impacted', value: '100,000' },
  { name: 'Schools Reached', value: '10' },
  { name: 'Materials Donated', value: '50,000' },
];

export const projects = [
  {
    id: 0,
    title: {
      name: 'The Book Drive 2023',
      heading: 'The Book Drive 2023',
    },
    subtitles: [
      {
        name: 'Materials Donated',
        details:
          'Over 12,000 educational materials for Kindergarten to SS3 classes were donated last year. Materials included exercise books, pencils, pens, and textbooks.',
      },
      {
        name: ' Schools Visited',
        details:
          'We visited 3 schools (Oshodi Comprehensive Senior High School, Odeogberin Nursery & Primary School, Bolade Junior Grammar School).',
      },
    ],
    src: [
      BookDrive2023,
      BookDrive20231,
      BookDrive20232,
      BookDrive20233,
      BookDrive20234,
      BookDrive20235,
      BookDrive20236,
      BookDrive20237,
      BookDrive20238,
      BookDrive20239,
    ],
  },
  {
    id: 1,
    title: {
      name: 'Digital Literacy Drive',
      heading:
        'Enhancing Access to Digital Literacy Skills in Low & Middle Income Communities',
    },
    subtitles: [
      {
        details:
          'Our relentless commitment to fostering sustainable change extends to initiatives such as the Google Africa Code Week and the Google Computer Science First projects, where we have empowered countless schools, students, and communities with essential digital literacy skills. Through our partnership with Google, the Cape Town Science Center and UNESCO and SAP.',
      },
      {
        name: 'Our impact',
        details:
          'We successfully trained and certified over 4,000 Nigerian students in 6 government schools in different low-to-middle-income communities across 5 states.',
      },
    ],
    src: [
      EducationAccess,
      EducationAccess1,
      EducationAccess2,
      EducationAccess3,
      EducationAccess4,
      EducationAccess5,
      EducationAccess6,
      EducationAccess7,
    ],
  },
];

export const testimonials = [
  {
    testimonial:
      'This has been a tremendous success for our school. Our students are very happy with the new additions to our library. Thank you for your generosity and support.',
    name: 'Principal Ojo (Bolade Junior Grammar School)',
  },
  {
    testimonial:
      'Thanks to GATE Academy for these books. Because of this, we have expanded the books in our library to make reading easier for our students. Your contribution has made a significant impact on Oshodi Comprehensive Senior High. ',
    name: 'Principal Felix (Oshodi Comprehensive Senior High School)',
  },
];

export const heroImages = [BookDrive1, BookDrive2, BookDrive3, BookDrive4];

export const bookDriveImages = [
  BookDriveImage1,
  BookDriveImage2,
  BookDriveImage3,
  BookDrive20231,
];
